import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'statustext' })
export class StatusCodeText implements PipeTransform {
  transform(status: number): string {
    let statusText: string;
    switch (status) {
      case 0:
        statusText = `pending`;
        break;
      case 1:
        statusText = `taken`;
        break;
      case 2:
        statusText = `disabled`;
        break;
      case 3:
        statusText = `expired`;
        break;
      default:
        statusText = `none`;
    }
    return statusText;
  }
}
