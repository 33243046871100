<div class="_wrapper">
  <div class="side_bg">
    <div class="logo_brand d-flex align-items-center">
      <span class="flex_x_center logo-pseudo-md cursor radius_10"></span>
      <h6 class="text-white font_34 weight_600 ml-3 mb-0">
        Federal University, Kashere
      </h6>
    </div>
    <div class="text">
      <h6 class="text-white fw-800 f-36">Online Examination Portal</h6>
      <p class="text-white">
        <a
          href="https://oayastech.com/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-white text-decoration-none"
          >Powered by Oayastech</a
        >
      </p>
    </div>
  </div>
  <div class="content position-relative">
    <div class="d-flex logout" (click)="logout()">
      <mat-icon class="text_app_danger cursor">logout</mat-icon>
      <span class="text_app_danger weight_800 cursor">Logout</span>
    </div>
    <div class="logo_brand mobile_view text-center">
      <span class="flex_x_center logo-pseudo-md cursor radius_10"></span>
    </div>

    <div class="form_area h-100 flex_y_center" *ngIf="loggedIn">
      <div class="text-center mb-3">
        <h6 class="font-weight-bold font_24 text-app-black text-capitalize">
          Welcome, {{ student?.fullName }} - {{ student?.regNumber }}
        </h6>

        <h6
          class="
            font-weight-bold font_16
            text-app-black text-capitalize
            d-flex
            justify-content-center
          "
          *ngIf="readyExams.length > 0"
        >
          Examination{{ readyExams.length > 1 ? "s" : "" }}:
          <h6
            class="ml-2 text-uppercase"
            *ngFor="let exam of readyExams; let last = last"
          >
            {{ exam?.course?.code
            }}{{ readyExams.length > 1 && !last ? "," : "" }}
          </h6>
        </h6>
        <p class="text_app_primary">
          Please get ready, your examination will begin automatically when the
          examination officer starts it <br />
          <span *ngIf="readyExams.length > 0"
            >N.B: Your exam officer will determine which examination you will
            start with</span
          >
        </p>
        <p class="text_app_grey">
          Good luck our dear student, we are rooting for you!
        </p>
      </div>
      <div class="waiting">
        <div class="loader1">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <div class="form_area mt-5" *ngIf="!loggedIn">
      <div class="top text-center">
        <h6 class="font-weight-bold font_24 text-app-black">
          Continue to examination
        </h6>
        <p>Enter your details below to get started</p>
      </div>

      <form [formGroup]="loginForm" (ngSubmit)="join()">
        <!-- <div class="error-badge">
          {{ error }}
        </div> -->
        <div class="">
          <div class="group w-100">
            <label for="firstname" class="fw-600 text-app-black"
              >Registration Number</label
            >
            <input
              type="text"
              id="firstname"
              class="form-control"
              formControlName="regNumber"
              placeholder=""
            />
          </div>
        </div>
        <button
          class="bg_app_primary text-white btn w-100"
          [disabled]="!loginForm.valid || loader"
        >
          {{ loader ? "Getting your data.." : "Continue" }}
        </button>
      </form>
      <footer class="text-center f-14">
        <span
          >NB: No electronic material is allowed into the examination hall</span
        >
      </footer>
    </div>
  </div>
</div>
