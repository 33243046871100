import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { numberToText } from './number';
import { StatusCodeText } from './status-code';

@NgModule({
  declarations: [
    StatusCodeText,
    numberToText
  ],
  imports: [CommonModule],
  exports: [
    StatusCodeText,
    numberToText
  ],
})
export class CustomPipeModule { }
