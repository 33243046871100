import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { UtilService } from '../util.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  socket = io(environment.baserUrl, {
    reconnectionDelayMax: 5000,
    query: {
      regNumber: this.util.getUserObject() ? this.util.getUserObject().regNumber : ''
    }
  });
  constructor(
    private util: UtilService
  ) { }

  connectSocket() {
    return this.socket.connect();
  }

}
