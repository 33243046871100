import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberText' })
export class numberToText implements PipeTransform {
    numberGroups = [
        {
            number: 1,
            text: 'first'
        },
        {
            number: 2,
            text: 'second'

        },
        {
            number: 3,
            text: 'third'

        }
    ]
    transform(num: number): string {
        const numItem = this.numberGroups.find(n => {
            return n.number === num;
        })
        return numItem ? numItem.text : '--';
    }
}
