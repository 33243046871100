
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription, TimeInterval } from 'rxjs';
import { StudentData } from 'src/app/Utilities/Models/student';
import { ApiService } from 'src/app/Utilities/Services/api.service';
import { WebsocketService } from 'src/app/Utilities/Services/socket/websocket.service';
import { UtilService } from 'src/app/Utilities/Services/util.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loader = false;
  phone_valid = 0;
  loginForm: FormGroup;
  loggedIn = false;
  error: string;
  student!: StudentData;
  readyExams: any = [];
  readyCountDown: any;
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private util: UtilService,
    private ws: WebsocketService,
  ) {
    this.loginForm = this.formbuilder.group({
      regNumber: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    if (this.util.getUserObject()) {
      this.loggedIn = true;
      this.student = this.util.getUserObject();
      // this.listenToSocket();
      this.emitSocket();
    } else {
      this.loggedIn = false;
    }

  }

  listenToReadyExams() {
    this.ws.socket.on('live-ready-exams', res => {
      if (res && res.data) {
        this.readyExams = res.data;
      }
    });
  }

  listenToActiveExams() {
    this.ws.socket.on('live-exams', res => {
      if (res) {
        console.log('live:', res);
      }
    });
  }

  // get active responses from scocket
  listenToSocket(): void {
    this.listenToActiveExams();
    this.listenToReadyExams();
  }

  // get active responses from scocket
  emitSocket(): void {
    this.myLiveExams();
    this.myReadyExams();
  }


  myLiveExams() {
    const body = {
      regNumber: this.student.regNumber
    };
    const countDown = setInterval(() => {
      this.ws.socket.emit('get-my-exams', body, (res: any) => {
        if (res && res.data.length > 0) {
          console.log(res.data);
          this.router.navigate(['/live', res.data[0]._id]);
          clearInterval(countDown);
          clearInterval(this.readyCountDown);
        }
      })
    }, 2000);
  }

  myReadyExams() {
    const body = {
      regNumber: this.student.regNumber
    };
    this.readyCountDown = setInterval(() => {
      this.ws.socket.emit('get-my-ready-exams', body, (res: any) => {
        if (res && res.data) {
          this.readyExams = res.data;
        }
      })
    }, 2000);
  }


  join() {
    this.loader = true;
    this.error = '';
    this.loginForm.value.regNumber = this.loginForm.value.regNumber.replace(/ /g, '');
    this.api.login(this.loginForm.value).subscribe((res: any) => {
      this.loader = false;
      this.student = res.data;
      this.util.setUserObject(res.data);
      this.loggedIn = true;
      // this.listenToSocket();
      this.emitSocket();
    },
      (err) => {
        this.loader = false;
      },
    );
  }

  logout() {
    this.util.logout();
    this.loggedIn = false;
  }

}

