import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UtilService } from '../Services/util.service';

@Injectable({
  providedIn: 'root'
})
export class HttpinterceptorService {
  constructor(
    private util: UtilService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.util.getToken();
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          // "Content-Type": "application/json",
          Accept: 'application/json',
          Authorization: `Bearer ${currentUser}`
        }
      });
    }
    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 503) {
            this.util.errorSnackbar('Service unavailable');
            return;
          }
          if (err.status === 403) {
            this.router.navigate(['/']);
          }
          if (err.status === 401) {
            this.util.errorSnackbar('Please login to continue');
            this.util.logout();
          }
          if (err.status === 0) {
            this.util.errorSnackbar('Please make sure you are connected to the internet');
            return;
          }
          if (err.status !== 0 && err.status !== 503 && err.status !== 401 && err.status !== 403) {
            this.util.errorSnackbar(err.error.message || 'Oops! something went wrong processing your request');
            return;
          }

        }
      }));
  }
}
